import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import queryString from "query-string";
import SocialSharing from "../SocialSharing"
import gtag from 'ga-gtag';
import { postResource } from "../../utils/api";

import styles from "../../styles/EventTicket.module.css";

const EventButton = (props) => {
  const { eventId, eventTitle, eventHandle, mainRoomName, eventDateTime, eventDuration, followupUrl, eventCancelled } = props;
  const [canEnter, setCanEnter] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const userName = `${user.given_name}\.${user.family_name}`.toLowerCase();
  const email = encodeURIComponent(user.email).replace(".", ".");
  const roomName = encodeURIComponent(mainRoomName);
  var startDate = new Date(eventDateTime);
  var overDate = moment(startDate).add(eventDuration, "m").toDate();
  var over = overDate < Date.now();
  var mainRoomUrl = `https://revelmoments.sw.work/api/auto-login?email=${email}&username=${userName}&room=${roomName}`;
  const queryParams = queryString.parse(window.location.search);

  useEffect(() => {
    if (queryParams && queryParams.showButton === "true") {
      setCanEnter(true);
    } else {
      const timeoutCounter = startDate - 3660000 - Date.now();
      if (timeoutCounter < 2147483647) {
        setTimeout(() => {
          setCanEnter(true);
        }, timeoutCounter);
      }
    }
  }, [startDate]);

  gtag('event', 'join_the_event', {
    poll_title: mainRoomUrl
  });

  const sendUserDetailsAndRedirectToSW = async () => {
    const token = await getAccessTokenSilently();
    postResource('clickedonEvent', token, { shopify_product_id: eventId });
    setTimeout(function() {
      window.location = mainRoomUrl;
    }, 100);
  }

  return (
    <>
      {!eventCancelled && <div>
        {over ? (
          <div>
            <Button href={followupUrl} className={`btn ${styles["btn-secondary"]}`}>
              Provide Feedback
            </Button>
            <SocialSharing over={over}
              eventTitle={eventTitle}
              eventHandle={eventHandle}></SocialSharing>
          </div>
        ) : canEnter ? (
          <Button onClick={sendUserDetailsAndRedirectToSW} className={`btn ${styles["btn-tertiary"]}`}>
            Join the room
          </Button>
        ) : (
          <Button className={`btn ${styles["btn-tertiary"]}`} disabled={true}>
            Join the room
          </Button>
        )}  
      </div>}
      {eventCancelled && <Button href="https://revelmoments.com/collections/featured-events" className={`btn ${styles["btn-secondary"]}`}>
        Upcoming Events
      </Button>}
    </>
  );
};

export default EventButton;
