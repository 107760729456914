import React from "react";
import styles from "../styles/CollectibleCard.module.css";

const CollectibleCard = (props) => {
  const { collectible } = props;
  const { name, description, imageThumbnailUrl, contract, id } = collectible;

  const placeholderImage = "https://via.placeholder.com/300x200?text=No+Image";

  const link = `${process.env.REACT_APP_VENLY_MARKETPLACE_DOMAIN}/inventory/HEDERA/${contract.address}/${id}`;

  return (
    <div className={styles["collectible-card"]}>
      <div className={styles["collectible-card__image"]}>
        <img src={imageThumbnailUrl || placeholderImage} alt={name} />
      </div>
      <div className={styles["collectible-card__content"]}>
        <div className={styles["collectible-card__left"]}>
          <h3>{name}</h3>
          <p>{description}</p>
        </div>
        <div className={styles["collectible-card__right"]}>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles["collectible-card__link"]}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={styles["collectible-card__icon"]}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollectibleCard;
