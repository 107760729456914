import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { fetchResource } from "../../utils/api";
import Loading from "../Loading";
import styles from "../../styles/EventTicket.module.css";
import EventCountdown from "./EventCountdown";
import EventButton from "./EventButton";
import EventNotFound from "./EventNotFound";
import moment from "moment";
import "moment-timezone";
import log from "loglevel";
import loglevelServerSend from "../../utils/loglevelServerSend";
import ApiFailure from "./ApiFailure";
import WhatToExpect from "./WhatToExpect"
import EventInfo from "./EventInfo"
import SocialSharing from "../SocialSharing"

const EventTicket = ({ event }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [eventMetafields, setEventMetafields] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [apiFailure, setApiFailure] = useState(false);

  loglevelServerSend(log, {
    url: process.env.REACT_APP_NEW_RELIC_API_ENDPOINT,
  });

  const logger = log.getLogger("client/src/components/Event/EventTicket");
  logger.enableAll();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await fetchResource(
          token,
          `event/metafields?id=${event.id}`
        );
        setEventMetafields(data);
        setLoading(false);
        logger.info("Success metafields found", data);
      } catch (e) {
        setLoading(false);
        setError(true);
        setApiFailure(true);
        logger.error("Failed metafields not found", e);
      }
    })();
  }, [getAccessTokenSilently]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEventOver = (eventDateTime, eventDuration) => {
    var startDate = new Date(eventDateTime);
    var overDate = moment(startDate).add(eventDuration, "m").toDate();
    return overDate < Date.now();
  }

  return (
    <div className="page-container">
      {!loading && apiFailure ? (
        <ApiFailure />
      ) : !loading &&
        eventMetafields &&
        Object.keys(eventMetafields).length > 0 ? (
        <>
          <section
            className={`${styles["top-content"]} container-fluid px-0 index-section--alt`}
            style={{ minHeight: "500px" }}>
            <div className={`row no-gutters d-flex justify-content-center align-items-center ${styles["pt-115"]}`}>
              <div className="col-sm-12">
                {!eventMetafields.cancelled && !isEventOver(eventMetafields.event_date_and_time, eventMetafields.event_duration) && (<h1 className={`${styles["left-col"]} m-auto`}>
                  Join the event live in...
                </h1>)}
                {eventMetafields.cancelled && (
                  <div>
                    <h1 className={`${styles["left-col"]} m-auto`}>
                      This event is no longer scheduled
                    </h1>
                    <p className={styles["h2-event-started"]}>
                      Please check our events page for more REVEL Moments experiences.
                    </p>
                  </div>
                )}
                {!eventMetafields.cancelled && isEventOver(eventMetafields.event_date_and_time, eventMetafields.event_duration) && (<h1 className={`${styles["left-col"]} m-auto`}>
                  This event has ended.
                </h1>)}
              </div>
              <div className="col-sm-12">
                {!eventMetafields.cancelled && (<div className={`col-sm-12 row justify-content-center text-center px-5 ${styles["right-col"]} `}>
                  <EventCountdown 
                    start={eventMetafields.event_date_and_time} 
                    eventDuration={eventMetafields.event_duration} />
                </div>)}
                <div className={`col-sm-12 ${styles["center"]}`}>
                  <EventButton 
                    eventId={event.id}
                    eventTitle={event.title}
                    eventHandle={event.handle}
                    mainRoomName={eventMetafields.main_room_name} 
                    eventDateTime={eventMetafields.event_date_and_time}
                    eventDuration={eventMetafields.event_duration}
                    followupUrl={eventMetafields.followup_url}
                    eventCancelled={eventMetafields.cancelled} />
                </div>
              </div>
            </div>
          </section>

          <section
            className={`${styles["bottom-content"]} container-fluid index-section--alt`}>
              <div className={styles["mt-40"]}>
                <div className={`row no-gutters justify-content-around ${styles["page-width"]}`}>
                  <div className={`col-sm-6 row p-0 mt-sm-2 mx-auto ${styles["flex-direction-reverse"]}`}>
                    {event.image ? (
                      <img
                        className={styles["host-img"]}
                        src={event.image.src}
                        alt="host"
                      />
                    ) : (
                      <img
                        className={styles["host-img"]}
                        src="https://cdn.shopify.com/s/files/1/0609/7141/7812/products/webclip.png"
                        alt="host"
                      />
                    )}

                    <img
                      className={styles["brand-logo-img"]}
                      src="https://cdn.shopify.com/s/files/1/0609/7141/7812/files/white-logo.png"
                      alt="Revel signature logo"
                    />
                  </div>
                  <div className={`col-sm-6 d-flex flex-column ${styles["pl-40"]}`}>
                    <div className={`row justify-content-between ${styles["padding-left-24"]}`}>
                      <div className="col">
                        <h1 className={`mt-2 mb-2 ${styles["h1-italic"]}`}>{event.title}</h1>
                        <h3 className="mb-2">{eventMetafields.host_name}</h3>
                        {!eventMetafields.cancelled && (
                          <>
                            <h5 className={`mb-0 ${styles["h5-ticket-datetime"]}`}>
                              { `${moment(eventMetafields.event_date_and_time).tz("America/New_York").format('ddd')}, ${moment(eventMetafields.event_date_and_time)
                                .tz("America/New_York")
                                .format("MMMM DD, YYYY")}` 
                              }
                            </h5>
                            <h5 className={`mb-2 ${styles["h5-ticket-datetime"]}`}>
                              {  moment(eventMetafields.event_date_and_time)
                                  .tz("America/New_York")
                                  .format("h:mma z")
                                  .replace("EST", "ET")
                                  .replace("EDT", "ET")
                              }
                            </h5>
                          </>
                        )}
                      </div>
                    </div>
                    <p
                      className={`mb-5 ${styles["padding-left-24"]}`}
                      dangerouslySetInnerHTML={{
                        __html: event.body_html || "This event has no description",
                      }}
                    ></p>

                    { !isEventOver(eventMetafields.event_date_and_time, eventMetafields.event_duration) && <SocialSharing 
                      eventTitle={event.title}
                      eventHandle={event.handle}></SocialSharing> }
                  </div>

                  <div className="col-sm-12 d-flex flex-column">
                    <EventInfo></EventInfo>
                  </div>

                  <div className="col-sm-12 d-flex flex-column">
                    <WhatToExpect></WhatToExpect>
                  </div>
                </div>
              </div>
          </section>
        </>
      ) : hasError ? (
        <EventNotFound />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default withAuthenticationRequired(EventTicket, {
  onRedirecting: () => <Loading />,
});
