import React, { Fragment, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";

const Home = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) loginWithRedirect();
  });

  return (
    <>
      {isAuthenticated ? (
        <Fragment>
          <div style={{ textAlign: "center", padding: "150px 20px" }}>
            <h1>EVENT NOT SELECTED</h1>
            <p>
              Please click the link in your email to access your event.
            </p>
          </div>
        </Fragment>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Home;