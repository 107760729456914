import React from "react";
import styles from "../styles/Button.module.css";

const Button = (props) => {
  const { title, onClick, ...restProps } = props;

  return (
    <button className={styles["button"]} onClick={onClick} {...restProps}>
      {title}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  title: "Button",
  onClick: () => {},
};
