import React from "react";

const NoEventTicket = () => {
  return (
    <div style={{ textAlign: "center", padding: "150px 20px" }}>
      <h1 className="section-header__title">
        Sorry! It looks like you don’t have a ticket to this event.
      </h1>
      <div className="rte text-spacing">
        <p>
          If you think this is an error, please log into your account and try
          again, or email us at info@revelmoments.com.
        </p>
        <p>
          Please see our&nbsp;
          <a
            className="text-link"
            href="https://revelmoments.com/collections/featured-events"
          >
            Events
          </a>
          &nbsp;page for upcoming events.
        </p>
      </div>
    </div>
  );
};

export default NoEventTicket;
