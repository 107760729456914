const axios = require("axios").default;

const baseURL =
  process.env.REACT_APP_BACKEND_DOMAIN || "http://localhost:3002/door";

// Handles GET Requests
// Resource can be: user, event, account, wallet
export const fetchResource = async (token, resource) => {
  try {
    const response = await axios.get(`${baseURL}/${resource}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    return await response.data;
  } catch (err) {
    throw err;
  }
};

// Handles POST Requests for register and login
export const postResource = async (resource, token, requestBody) => {
  try {
    const response = await axios.post(
      `${baseURL}/${resource}`,
      requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        }
      }
    );
    return await response.data;
  } catch (err) {
    console.log(`${err}`);
    throw err;
  }
};

// Handles GET Requests for a specific volunteer (admin and volunteers have access)
// export const fetchVolunteer = async (id) => {
//   try {
//     const response = await axiosWithAuth().get(`${baseURL}/volunteers/${id}`);
//     return await response.data;
//   } catch (err) {
//     console.log(`Error: ${err.response.data.message}`);
//     throw err;
//   }
// };

// Handles GET Requests for a volunteer's tasks (admin and volunteers have access)
// export const fetchTasks = async (id) => {
//   try {
//     const response = await axiosWithAuth().get(
//       `${baseURL}/volunteers/tasks/${id}`
//     );
//     return await response.data;
//   } catch (err) {
//     console.log(`Error: ${err.response.data.message}`);
//     throw err;
//   }
// };

// Handles POST Requests for tasks (only admins have access)
// export const postTask = async (task) => {
//   try {
//     const response = await axiosWithAuth().post(`${baseURL}/tasks`, task);
//     return await response.data;
//   } catch (err) {
//     console.log(`Error: ${err.response.data.message}`);
//     throw err;
//   }
// };

// Handles PUT Requests for tasks (only admins have access)
// export const putTask = async (task, id) => {
//   try {
//     const response = await axiosWithAuth().put(`${baseURL}/tasks/${id}`, task);
//     return await response.data;
//   } catch (err) {
//     console.log(`Error: ${err.response.data.message}`);
//     throw err;
//   }
// };
