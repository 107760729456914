import React, { useEffect, useState } from "react";
import { fetchResource } from "../../utils/api";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Loading";
import EventTicket from "./EventTicket";
import NoEventTicket from "./NoEventTicket";
import EventNotFound from "./EventNotFound";
import log from "loglevel";
import loglevelServerSend from "../../utils/loglevelServerSend";
import ApiFailure from "./ApiFailure";
const Event = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState();
  const [hasTicket, setHasTicket] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [apiFailure, setApiFailure] = useState(false);

  loglevelServerSend(log, {
    url: process.env.REACT_APP_NEW_RELIC_API_ENDPOINT,
  });

  const logger = log.getLogger("client/src/components/Event/Event");
  logger.enableAll();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const eventsData = await fetchResource(token, "events");
        const eventData = await fetchResource(token, "event?id=" + id);
        logger.info("Success event found", eventData);
        logger.info("Success events found", eventsData);
        if (eventsData.length > 0 && Object.keys(eventData).length > 0) {
          if (eventData.id && eventsData.includes(eventData.id.toString())) {
            setEvents(eventsData);
            setEvent(eventData);
            setHasTicket(true);
            logger.info("Success user has a ticket", eventData.id);
          } else {
            setHasTicket(false);
            setEvents(eventsData);
            setEvent(eventData);
          }
        }
        setLoading(false);
      } catch (e) {
        logger.error("Failed to find event data or event ids", e);
        setLoading(false);
        setApiFailure(true);
      }
    })();
  }, [getAccessTokenSilently]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!loading && apiFailure ? (
        <ApiFailure />
      ) : (
        <>
          {events &&
          event &&
          events.length > 0 &&
          Object.keys(event).length > 0 ? (
            <>{hasTicket ? <EventTicket event={event} /> : <NoEventTicket />}</>
          ) : (
            <>{loading ? <Loading /> : <EventNotFound />}</>
          )}
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(Event, {
  onRedirecting: () => <Loading />,
});
