import React, { useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import Event from "../components/Event/Event";

const EventPage = (props) => {
  const params = props.match.params;
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const email = values?.email;
  const privateEvent = values?.privateEvent === "true" ? true : false;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect({ email, privateEvent, appState: { returnTo: window.location.pathname } });
  }, [isLoading, isAuthenticated, email, privateEvent]);

  return (
    <>
      {
        isAuthenticated ? (<Event id={params.id} />) : (<Loading />)
      }
    </>
  );
};

export default EventPage;