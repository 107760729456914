import React from "react";
import styles from "../styles/Footer.module.css";
import "../styles/BackToTop.module.css";
import { ReactComponent as TikTokLogo } from "../assets/tiktok-brands.svg";
import { ReactComponent as InstaLogo } from "../assets/instagram-v2.svg";
import { ReactComponent as FacebookLogo } from "../assets/facebook.svg";
import { ReactComponent as TwitterLogo } from "../assets/twitter-brands.svg";
import BackToTop from "./BackToTop";

const Footer = () => (
  <footer className={styles["footer"]}>
    <div className={styles["page-width"]}>
      <div className={styles["footer-content"]}>
        <div className={styles["footer-content__left"]}>
          <div className={styles["footer-logo"]}>
            <img
              className={styles["revel-logo"]}
              src="https://cdn.shopify.com/s/files/1/0609/7141/7812/files/white-logo.png?v=1642528202"
              alt="REVEL Moments logo"
            />
          </div>
          <p className={styles["copyright-text-p"]}>
            Creating moments that matter.
          </p>
          <p className={styles["copyright-text"]}>
            COPYRIGHT &copy; 2022 REVEL MOMENTS INC. ALL RIGHTS RESERVED.
          </p>
          <div className={styles["footer-padding"]}></div>
        </div>

        <div className={styles["footer-content__right"]}>
          <ul>
            <li className={styles["footer-li-header"]}>Company</li>
            <li>
              <a href="https://revelmoments.com/pages/about">
                About Us
              </a>
            </li>
            <li>
              <a href="https://revelmoments.com/pages/contact-us">
                Contact Us
              </a>
            </li>
            <li>
              <a href="https://revelmoments.com/policies/terms-of-service">
                Terms
              </a>
            </li>
            <li>
              <a href="https://revelmoments.com/policies/privacy-policy">
                Privacy
              </a>
            </li>
            <li>
              <a href="https://revelmoments.com/pages/frequently-asked-questions">
                FAQs
              </a>
            </li>
          </ul>
          <ul className={styles["footer-flex-two"]}>
            <li className={styles["footer-li-header"]}>Connect</li>
            <li>
              <i>
                <InstaLogo />
              </i>
              <a
                className={styles["fa-a-brands"]}
                href="https://www.instagram.com/revelmoments/"
              >
                Instagram
              </a>
            </li>
            <li>
              <i>
                <TikTokLogo />
              </i>
              <a
                className={styles["fa-a-brands"]}
                href="https://www.tiktok.com/@revelmoments"
              >
                TikTok
              </a>
            </li>
            <li>
              <i>
                <TwitterLogo />
              </i>
              <a
                className={styles["fa-a-brands"]}
                href="https://twitter.com/revelmoments"
              >
                Twitter
              </a>
            </li>
            <li>
              <i>
                <FacebookLogo />
              </i>
              <a
                className={styles["fa-a-brands"]}
                href="https://www.facebook.com/revelmomentsinc/"
              >
                Facebook
              </a>
            </li>
            <li></li>
          </ul>
        </div>
        <BackToTop />
      </div>
      <p className={styles["copyright-text__mobile_p"]}>
        Creating moments that matter.
      </p>
      <p className={styles["copyright-text__mobile"]}>
        COPYRIGHT &copy; 2022 REVEL MOMENTS INC. ALL RIGHTS RESERVED.
      </p>
    </div>
  </footer>
);

export default Footer;
