import React from "react";

import styles from "../../styles/EventInfo.module.css";

import { ReactComponent as WifiLogo } from "../../assets/Path-2.svg";
import { ReactComponent as NoiseLogo } from "../../assets/Path-3.svg";
import { ReactComponent as VpnLogo } from "../../assets/Path-4.svg";
import { ReactComponent as HeadsetLogo } from "../../assets/Group-1.svg";

const EventInfo = () => {
  return (
    <>
        <div className={`row justify-content-around ${styles["display-content"]}`}>
            <div className={styles["info-experience"]}>
                <div className={styles["information-experience-container"]}>
                    <h3 className={`text-center pb-8 ${styles["info-experience-header"]}`}>For the best REVEL Moments experience:</h3>
                    <div className={styles["information-experience-list"]}>
                        <div className={styles["information-experience-item"]}>
                            <WifiLogo />
                            <div className={styles["information-experience-info"]}>                
                                <div className={styles["information-experience-description"]}>
                                    <p>Join from a device with a strong WiFi connection</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles["information-experience-item"]}>
                            <HeadsetLogo />
                            <div className={styles["information-experience-info"]}>                
                                <div className={styles["information-experience-description"]}>
                                    <p>Avoid using headsets or Bluetooth audio devices such as Apple AirPods</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles["information-experience-item"]}>
                            <NoiseLogo />
                            <div className={styles["information-experience-info"]}>                
                                <div className={styles["information-experience-description"]}>
                                    <p>Limit background noise from your location</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles["information-experience-item"]}>
                            <VpnLogo />
                            <div className={styles["information-experience-info"]}>                
                                <div className={styles["information-experience-description"]}>
                                    <p>Disconnect from any VPN</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default EventInfo;
