import React from "react";

import styles from "../../styles/WhatToExpect.module.css";

const WhatToExpect = () => {
  return (
    <>
        <div id="whatToExpect" className="index-section text-left border-0 border-t border-b border-solid medium-up--one-whole large-up--six-eighths widescreen--six-eighths mb-0 pb-0">
            <div className="page-width">
                <h2 className={styles["section-header__title"]}>What to expect</h2>
                <div className="theme-block">
                    <div className="rte text-left">
                        We want REVEL Moments events to be fun, intimate, and empathetic — leaving you with moments of elevation, pride, and insight about your host and their path in life. To do this we want you to be part of the event so get ready:
                        <ul className="ul-styled">
                            <li>To be on screen (as the audience will be visible)!</li>
                            <li>Submit a question in advance of the event as you may get a chance to join us on stage and ask the question live.</li>
                            <li>If so moved (+ over 21 years old!) grab an adult beverage and sit back to enjoy the athlete joining you in the room.</li>
                        </ul>   
                        A typical REVEL Moments event lasts between 45 - 60 minutes. For more details check out our <a href="https://revelmoments.com/pages/frequently-asked-questions">FAQs</a>.
                    </div>
                </div>
                <h3 className={styles["section-header__title"]}>Troubleshooting</h3>
                <div className="theme-block">
                    <div className="rte">
                        <p>If you are unable to connect to the event room, please ask for help in our chat by clicking the help icon in the top right of your screen.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default WhatToExpect;
