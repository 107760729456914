import React from "react";
import styles from "../styles/Navbar.module.css";

import { Container, Navbar, NavbarBrand } from "reactstrap";
import { useState } from "react";

// import { useAuth0 } from "@auth0/auth0-react";

require("dotenv").config();

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header
      className="nav-container"
      style={{ position: "fixed", width: "100%", top: "0px", zIndex: "1000" }}
    >
      <Navbar
        style={{ justifyContent: "start", boxShadow: "none", padding: "0" }}
      >
        <Container className={styles["navbar-wrapper"]}>
          <div className={styles["navbar-brand"]}>
            <NavbarBrand href={process.env.REACT_APP_LOGOUT}>
              <img
                className={styles["header-logo"]}
                src="https://cdn.shopify.com/s/files/1/0609/7141/7812/files/white-logo.png?v=1636407480"
                alt="REVEL Moments logo"
              />
            </NavbarBrand>
            <div
              onClick={handleShowMenu}
              className={styles["hamburger-menu-button"]}
            >
              {!showMenu ? (
                <div
                  onClick={handleShowMenu}
                  className={styles["hamburger-open"]}
                >
                  <div className={styles["menu-bar"]}></div>
                  <div className={styles["menu-bar"]}></div>
                  <div className={styles["menu-bar"]}></div>
                </div>
              ) : (
                <div id={styles["mdiv"]} className={styles["hamburger-close"]}>
                  <div className={styles["mdiv"]}>
                    <div className={styles["md"]}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </Navbar>
      <ul
        className={`${styles["hamburger-menu"]} ${
          showMenu && styles["show-dropdown-menu"]
        }`}
      >
        <li className={styles["menu-item"]}>
          <a
            className={styles["menu-item-link"]}
            href="https://revelmoments.com/collections/featured-events"
            style={{ textDecoration: "none" }}
          >
            Events
          </a>
        </li>
        {/* <li className={styles["menu-item"]}>
          <a
            className={styles["menu-item-link"]}
            href="/collectibles"
            style={{ textDecoration: "none" }}
          >
            Collectibles
          </a>
        </li> */}

        <li className={styles["menu-item"]}>
          <a
            className={styles["menu-item-link"]}
            href="https://revelmoments.com/pages/about"
            style={{ textDecoration: "none" }}
          >
            About
          </a>
        </li>

        <li className={styles["menu-item"]}>
          <a
            className={styles["menu-item-link"]}
            href="https://revelmoments.com/pages/frequently-asked-questions"
            style={{ textDecoration: "none" }}
          >
            FAQs
          </a>
        </li>

        <li className={styles["menu-item"]}>
          <a
            className={styles["menu-item-link"]}
            href="https://revelmoments.com/pages/contact-us"
            style={{ textDecoration: "none" }}
          >
            Contact
          </a>
        </li>
      </ul>

      {showMenu && (
        <div
          className={styles["header-overlay-2"]}
          onClick={handleShowMenu}
        ></div>
      )}
    </header>
  );
};

export default NavBar;
