import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => {
  return (
    <div style={{ minHeight: "500px", position: "relative" }}>
      <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
        <Spinner
          animation="border"
        />
      </div>
    </div>
  );
};

export default Loading;
