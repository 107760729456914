import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { VenlyConnect } from "@venly/connect";
import CollectibleCard from "../components/CollectibleCard";
import Button from "../components/Button";
import styles from "../styles/Collectibles.module.css";

const Collectibles = () => {
  const { isAuthenticated, loginWithPopup } = useAuth0();
  const [venlyCollectibles, setVenlyCollectibles] = useState([]);
  const [venlyConnectionStatus, setVenlyConnectionStatus] =
    useState("disconnected");

  const PageWrapper = (props) => {
    const { children } = props;

    const styles = {
      wrapper: { textAlign: "center", padding: "150px 20px" },
    };

    return (
      <div style={styles.wrapper}>
        <h1>My Collectibles </h1>
        {isAuthenticated ? (
          <>{children}</>
        ) : (
          <Button title="Login" onClick={() => loginWithPopup()} />
        )}
      </div>
    );
  };

  const venlyConnect = new VenlyConnect(process.env.REACT_APP_VENLY_CLIENT_ID, {
    environment: process.env.REACT_APP_VENLY_ENV_LVL,
  });

  const getAllNFTs = async (wallets) => {
    if (!wallets) {
      return [];
    }
    wallets.forEach((wallet) => {
      venlyConnect.api
        .getNonfungibles(wallet.id)
        .then((nfts) => {
          setVenlyCollectibles(...venlyCollectibles, nfts);
        })
        .finally(() => {
          setVenlyConnectionStatus("connected");
        });
    });
  };

  const connectToVenly = async () => {
    await venlyConnect.flows
      .getAccount("HEDERA")
      .then((account) => {
        setVenlyConnectionStatus("loading");
        getAllNFTs(account.wallets);
      })
      .catch((error) => {
        console.log(error);
        setVenlyConnectionStatus(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated && venlyConnectionStatus === "disconnected") {
      connectToVenly();
    }
  }, [isAuthenticated, venlyConnectionStatus, connectToVenly]);

  return (
    <PageWrapper>
      {venlyConnectionStatus !== "connected" && (
        <>
          <Button title="Connect Wallet" onClick={connectToVenly} />
          {venlyConnectionStatus === "loading" && <Loading />}
        </>
      )}
      {venlyConnectionStatus === "connected" && (
        <>
          {venlyCollectibles && venlyCollectibles.length > 0 ? (
            <div className={styles["collectibles-content__wrapper"]}>
              {venlyCollectibles.map((collectible) => (
                <CollectibleCard
                  collectible={collectible}
                  key={collectible.id}
                />
              ))}
            </div>
          ) : (
            <>
              <h3>No Collectibles Found</h3>
              <Button
                title="Purchase"
                onClick={() => {
                  window.open(process.env.REACT_APP_VENLY_SHOP_URL, "_blank");
                }}
              />
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default withAuthenticationRequired(Collectibles, {
  onRedirecting: () => <Loading />,
});
