import React from "react";

const ApiFailure = () => {
  return (
    <div style={{ textAlign: "center", padding: "150px 20px" }}>
      <h1 className="section-header__title">Something is wrong.</h1>
      <div className="rte text-spacing">
        <p>
          Please try again in a few minutes or contact us at
          info@revelmoments.com.
        </p>
      </div>
    </div>
  );
};

export default ApiFailure;
