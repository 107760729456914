import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import Test from "../components/Test";
import User from "../components/User";
import Account from "../components/Account";

const TestPage = () => {
  return (
    <>
      <Test />
      <Account />
      <User />
    </>
  );
};

export default withAuthenticationRequired(TestPage, {
  onRedirecting: () => <Loading />,
});
