import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar";
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Test from "./views/Test";
import Event from "./views/Event";
import Collectibles from "./views/Collectibles";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

const App = () => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      <div id="app">
        <NavBar />
        <Loading />
        <Footer />
      </div>
    );
  }

  if (error) {
    return <div>ERROR: {error.message}</div>;
  }

  return (
    <Router history={history}>
      <div id="app">
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/profile" component={Profile} />
          <Route path="/test" component={Test} />
          <Route path="/event/:id" component={Event} />
          <Route path="/collectibles" component={Collectibles} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
