import React, { useEffect, useState } from "react";
import { fetchResource } from "../utils/api";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import log from 'loglevel';
import loglevelServerSend from "../utils/loglevelServerSend";

const User = () => {
  const [user, setUser] = useState();
  const { getAccessTokenSilently } = useAuth0();

  loglevelServerSend(log, {url: process.env.REACT_APP_NEW_RELIC_API_ENDPOINT});

  const logger = log.getLogger('client/src/components/User');
  logger.enableAll();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const userData = await fetchResource(token, "user");
        setUser(userData);
        logger.info('Success user data found', userData);
      } catch (e) {
        logger.error('Failed to get user data', e);
      }
    })();
  }, [getAccessTokenSilently]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="result-block-container">
        {user ? (
          <Loading />
        ) : (
          <div className="result-block" data-testid="api-result">
            <h3>Fetch User Result</h3>
            <span>{JSON.stringify(user.data)}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(User, {
  onRedirecting: () => <Loading />,
});
