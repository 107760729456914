import React from "react";

import styles from "../../styles/EventTicket.module.css";

import moment from "moment";
import "moment-timezone";

import Countdown, { zeroPad, calcTimeDelta } from "react-countdown";

const EventCountdown = ({ start, eventDuration }) => {
  var hasStarted = new Date(start) < Date.now();

  var startDate = new Date(start);
  var overDate = moment(startDate).add(eventDuration, "m").toDate();
  var over = overDate < Date.now();

  const calculatedTime = calcTimeDelta(start);

  const renderer = ({ days, hours, minutes }) => {
    return (
      <>
        <div className="row flex-grow-1">
          <div className="col p-0">
            <h2 className="px-sm-4 m-0">{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}</h2>
          </div>
        </div>
        <div className="col-sm-12 row flex-grow-1">
          <div className="col p-0">
            <h5 className={`px-sm-4 m-0  ${styles["h5-countdown"]}`}>Days:Hrs:Mins</h5>
          </div>
        </div>
        <div className="col-sm-12">
          { (calculatedTime.hours > 1) && <h4>Your seat to this event will become available at {
              `${moment(start).tz("America/New_York").format('ddd')}, ${moment(start)
                .tz("America/New_York")
                .format("MMM D h:mma z")
                .replace("EST", "ET")
                .replace("EDT", "ET")}`
              }
            </h4> }
          { (calculatedTime.hours <= 1) && <h4>Your seat is now available. Enjoy the show!</h4> }
        </div>
      </>
    );
  };

  return (
    <>
      { (hasStarted && !over) ? (
        <p className={styles["h2-event-started"]}>
          This event has already started. Please click the button below to
          continue to your REVEL Moment.
        </p>
      ) : (over) ? 
        <p className={styles["h2-event-started"]}>
          We hope you enjoyed the experience.
        </p>
      : (
        <Countdown date={start} renderer={renderer} />
      )}
    </>
  );
};

export default EventCountdown;
