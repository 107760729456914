import React, { useState } from "react";

import styles from "../styles/SocialSharing.module.css";

const SocialSharing = ({ eventTitle, eventHandle, over }) => {
    const [isCopiedVisible, setCopiedVisible] = useState(false)

    const envLevel = process.env.REACT_APP_ENV_LEVEL;

    const classNames = over ? 'social-sharing__link__over' : ''
    const spanClassName = over ? 'light-gray-over' : 'light-gray'
    const linkCopiedClassName = over ? 'social-sharing__link-copied-over' : 'social-sharing__link-copied'

    let socialMediaURL;
    if(envLevel === 'development') {
        socialMediaURL = `https://revel-moments-test.revelmoments.com/products/${eventHandle}`;
    } else {
        socialMediaURL = `https://revelmoments.com/collections/featured-events/products/${eventHandle}`
    }

    const copyLink = () => {
        navigator.clipboard.writeText(socialMediaURL);
        setCopiedVisible(true)
        setTimeout(() => {
            setCopiedVisible(false)
        }, 2000);
    }

  return (
    <>
        <div className={`mb-5 ${styles["social-sharing"]}`}>
            <span className={styles[spanClassName]}>SHARE: </span>
            <a target="_blank" rel="noopener" data-social="facebook"
                href={`//www.facebook.com/sharer.php?u=${socialMediaURL}&quote=I'm In! I got a ticket to this @revelmomentsinc event.`}
                className={styles["social-sharing__link"]} title="Share on Facebook">
                <i className={`fa-brands fa-facebook ${styles[classNames]}`}></i>
                <span className={styles["visually-hidden"]}>Share on Facebook</span>
            </a>
            <a target="_blank" rel="noopener" data-social="twitter" 
                href={`//twitter.com/share?text=I'm In! I got a ticket to this @revelmoments event. ${eventTitle}&url=${socialMediaURL}`}
                className={styles["social-sharing__link"]} title="Tweet on Twitter">
                <i className={`fa-brands fa-twitter ${styles[classNames]}`}></i>
                <span className={styles["visually-hidden"]}>Tweet on Twitter</span>
            </a>
            <span data-social="copy" className={`copy-link ${styles["social-sharing__link"]}`}>
                <i className={`fa-solid fa-link-horizontal ${styles[classNames]}`} onClick={copyLink}></i>
                { isCopiedVisible && <span className={styles[linkCopiedClassName]}>Link copied</span> }
                <span className={styles["visually-hidden"]}>Copy URL</span>
            </span>
        </div>
    </>
  );
};

export default SocialSharing;






